.LoginLayout {
  width: 360px;
  max-width: 100%;
  margin: 64px auto;

  > .container {
    padding: 1.5rem;
    border-radius: 0.25rem;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12);

    @media (max-width: 450px) {
      box-shadow: none;
    }

    > .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      margin: 0 auto;
      border-radius: 50%;
      font-size: 24px;
      background-color: #1225b1;
      color: white;
    }

    > .title {
      text-align: center;
      margin: 12px 0 20px;
    }
  }
}
