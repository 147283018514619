@import 'src/styles/variables';

.HeaderLink {
  display: flex;
  align-items: center;
  padding: 0 12px;
  text-decoration: none;
  color: $gray-700;
  font-size: 14px;

  &:hover {
    color: $link-hover-color;
  }

  &.-active {
    color: $link-color;
    font-weight: $font-weight-bold;
  }
}
