.DashboardTableCancelButton {
  display: inline-flex;
  height: 2rem;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  background: white;
  text-align: center;
  padding: 0.25rem 0.75rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;

  &:active {
    background: rgba(0, 0, 0, 0.02);
    box-shadow: none;
  }
}
