.NotificationsListContent {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  > .title {
    line-height: 1rem;
  }

  > .description,
  > .distance {
    font-size: 0.75rem;
    color: rgba(0, 0, 0, 0.5);
  }
}
