.DashboardButton {
  display: flex;
  gap: 1rem;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.5rem;
  padding: 0.675rem 1rem;
  background: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);

  &:hover {
    background: rgba(0, 0, 0, 0.02);
  }

  &:active {
    background: rgba(0, 0, 0, 0.04);
    box-shadow: none;
  }

  > .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > .title {
      font-weight: 500;
      font-size: 1rem;
    }

    > .caption {
      color: rgba(0, 0, 0, 0.5);
    }
  }
}
