.CircleLoadingAnimatedIcon {
  display: flex;
  animation: CircleLoadingAnimatedIconRotate 0.8s linear infinite;
}

@keyframes CircleLoadingAnimatedIconRotate {
  to {
    transform: rotate(360deg);
  }
}
