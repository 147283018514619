@import "src/styles/variables";

.AdminTable {
  position: relative;
  margin-bottom: $spacer;

  &.-loading {
    opacity: 0.7;
  }

  > .table {
    margin-bottom: 0;
  }
}
