.NotificationsPopup {
  position: absolute;
  top: calc(100% + 1rem);
  right: 46px;
  z-index: 5;
  width: 100%;
  max-width: 380px;
  border-radius: 0.375rem;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  animation-name: NotificationsPopup;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;

  [lang=he] & {
    right: auto;
    left: 46px;
  }
}

@keyframes NotificationsPopup {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
