.DashboardChart {
  position: relative;
  padding: 1rem;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.15);

  > .header {
    display: flex;
    justify-content: space-between;
    align-items: start;

    > .title {
      font-weight: 500;
    }
  }

  > .chart {
    display: flex;
    justify-items: center;
    height: 300px;
    margin-top: 1rem;
  }
}
