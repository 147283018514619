@import 'src/styles/mixins';

.AdminToolbar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 20px 0;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    align-items: flex-start;
  }
}
