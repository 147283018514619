.DashboardTableRow {
  vertical-align: middle;

  &:hover {
    background: rgba(0, 0, 0, 0.03);
  }

  &:last-child > td {
    border: none;
  }

  &.-no-border {
    > td {
      border: none;
    }
  }

  &.-group {
    cursor: pointer;
  }

  > .action {
    width: 140px;
    text-align: end;
  }
}
