@import "src/styles/variables";

.TablePagination {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.75rem;
  margin: $spacer 0 0;

  > .size {
    width: auto;
  }

  > .list {
    margin-bottom: 0;
  }
}
