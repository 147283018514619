@import "src/styles/variables";

.NotificationsListActions {
  > .control {
    line-height: 1rem;
    padding: 0.25rem 0.5rem;
    border: none;
    font-size: 0.75rem;
    background: rgba($primary, 0.05);
    color: $primary;

    &:hover {
      background: rgba($primary, 0.1);
    }

    &:active {
      background: rgba($primary, 0.15);
    }
  }
}
