$font-family-sans-serif: Roboto, sans-serif;
$font-size-base: 0.875rem;

$font-weight-lighter: 100;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 500;
$font-weight-bolder: 700;

$dt-font-weight: 400;

$body-color: #000;
$blue: #1225b1;

$link-decoration: none;
$link-hover-decoration: none;

$headings-font-weight: 400;
$headings-margin-bottom: 0;

$h1-font-size: $font-size-base * 2;
$h2-font-size: $font-size-base * 1.75;
$h3-font-size: $font-size-base * 1.5;
$h4-font-size: $font-size-base * 1.25;
$h5-font-size: $font-size-base * 1.125;
$h6-font-size: $font-size-base;

$table-th-font-weight: 500;

$dropdown-item-padding-y: 6px;
$dropdown-item-padding-x: 16px;
$dropdown-min-width: auto;

$input-btn-focus-width: 0;

$modal-header-padding-y: 1rem;
$modal-header-padding-x: 1rem;
$modal-header-padding: $modal-header-padding-y $modal-header-padding-x 0;
$modal-content-border-width: 0;

$pagination-margin-start: 0.25em;

$zindex-dropdown: 100;
$zindex-sticky: 102;
$zindex-fixed: 103;
$zindex-modal-backdrop: 104;
$zindex-modal: 105;
$zindex-popover: 106;
$zindex-tooltip: 107;
