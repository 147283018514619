.NotificationsMenuItem {
  display: flex;
  gap: 0.375rem;
  align-items: center;
  padding: 0.375rem 0.5rem;
  margin-bottom: -1px;
  border: none;
  border-bottom: 1px solid transparent;
  background: transparent;

  > .label {
    color: rgba(0, 0, 0, 0.5);
  }

  &:hover {
    > .label {
      color: rgba(0, 0, 0, 0.7);
    }
  }

  &.-active {
    border-bottom-color: black;

    > .label {
      color: rgba(0, 0, 0, 1);
    }
  }
}
