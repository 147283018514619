.Spinner {
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;

  > .arc {
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-style: solid;
    border-color: currentColor;
    border-top-color: transparent;
    border-radius: 50%;
    animation: SpinnerRotate 0.8s linear infinite;
    content: '';
  }
}

@keyframes SpinnerRotate {
  to {
    transform: rotate(360deg);
  }
}
