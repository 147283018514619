@import "src/styles/variables";

.NotificationsMenuCounter {
  padding: 0 0.5rem;
  border-radius: 0.125rem;
  font-weight: $font-weight-bold;
  font-size: 0.75rem;
  background: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.5);

  &.-active {
    background: #fdd020;
    color: black;
  }
}
