@import 'src/styles/variables';

.HeaderNotificationsToggle {
  position: relative;
  height: auto;
  padding: 0.5rem;
  border: none;
  background: transparent;
  color: rgba(0, 0, 0, 0.25);
  font-size: 1.25rem;

  &:hover {
    color: rgba(0, 0, 0, 0.3);
  }

  &.-unread {
    color: $portal-color-primary;

    &:hover {
      color: darken($portal-color-primary, 5%);
    }
  }

  > .count {
    position: absolute;
    left: 50%;
    bottom: 50%;
    display: block;
    padding: 0 0.25rem;
    margin: 0 0 0.125rem 0.125rem;
    border-radius: 0.1875rem;
    font-size: 0.685rem;
    font-weight: $font-weight-bold;
    background: #fdd020;
    color: black;
  }
}
