@import "src/styles/variables";

.NotificationsHeaderReadAllButton {
  display: flex;
  border: none;
  padding: 0;
  background: transparent;
  font-size: 0.75rem;
  color: $portal-color-primary;

  &:hover {
    text-decoration: underline;
  }
}
