.AdminTableHeadColumnButton {
  display: flex;
  gap: 0.25rem;
  align-items: center;
  padding: 0;
  border: none;
  background: transparent;
  color: inherit;
  font-weight: inherit;

  > .icon {
    &.-transparent {
      opacity: 0;
    }
  }

  &:hover {
    > .icon {
      &.-transparent {
        opacity: 0.5;
      }
    }
  }
}
