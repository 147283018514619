@import 'bootstrap';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

$color-light-gray: #ccc;
$color-gray: #656565;
$color-dark-blue: #03256c;
$color-blue: #3155a3;
$color-error: #f44336;
$color-muted: #888;
$color-dark: #211f24;

$portal-color-primary: #1225b1;
$portal-color-gray: #808080;
$portal-color-green-dark: #009a44;
$portal-color-orange: #f91212;
$portal-color-beige: #e6e4e0;

$portal-font-weight-medium: 500;
