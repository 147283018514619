@import "src/styles/variables";

.AdminTableLoader {
  display: flex;
  align-items: baseline;
  gap: 0.375rem;
  background: white;
  box-shadow: 0 0 2rem 2rem white;

  > .icon {
    font-size: 0.75rem;
  }
}
