@import 'src/styles/variables';

.AdminTableActions {
  display: flex;
  justify-content: flex-end;

  .toggle {
    color: $link-color;

    &:hover {
      color: $link-hover-color;
    }
  }
}
