@import "src/styles/variables";

.DropdownMenuItem {
  display: flex;
  align-items: center;

  > .icon {
    margin-right: 8px;
    color: $gray-600;
  }
}
