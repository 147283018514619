.DashboardTableSelect {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  background: white;
  height: 2rem;
  padding: 0.25rem 0.5rem;
}
